import { useMemo, useState } from 'react';
import { Outlet, Link, useOutletContext } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import * as Sentry from '@sentry/react';

import PATHS, { getPath } from '@routes/paths';
import { TColumn } from '@typedefs/app';
import useTable, { emptyRows } from '@hooks/useTable';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';

import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import Page from '@components/Page';
import BreadcrumbsHeader from '@components/BreadcrumbsHeader';
import Iconify from '@components/Iconify';
import Toolbar from './Toolbar';
import { RECEIPT_LIST_HEADERS, ReceiptListHeader, ReceiptListItem } from './config';
import useData from './useData';
import Row from './Row';
import useAuth from '@hooks/useAuth';
import { receiptsViewCacheId } from '@typedefs/donation';

// ----------------------------------------------------------------------
type TOutletContext = {
  type: 'receipt';
  readonly columns: TColumn<ReceiptListHeader>[];
  readonly data: ReceiptListItem[];
  readonly dateFrom?: Date;
  readonly dateTo?: Date;
  readonly orderBy?: string;
};

export function useContext() {
  return useOutletContext<TOutletContext>();
}
// ----------------------------------------------------------------------
export default function ReceiptsList() {
  const { user } = useAuth();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { donorsWithDonations, getDonorsForReceipt } = useDonation();
  const navigate = useNavigate();
  const canEdit = hasAccess([Role.editor]);

  const unreceiptedExist = useMemo(
    () => !!getDonorsForReceipt(donorsWithDonations).length,
    [getDonorsForReceipt, donorsWithDonations]
  );

  // ----- TABLE -------
  const [search, setSearch] = useState('');
  const {
    dateFrom,
    dateTo,
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    setDateFrom,
    setDateTo,
    setPage,
    setColumns,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable(
    {
      defaultRowsPerPage: 25,
      defaultColumns: RECEIPT_LIST_HEADERS,
      defaultOrderBy: 'number',
      defaultOrder: 'desc',
      defaultDateFrom: undefined,
      defaultDateTo: undefined,
    },
    receiptsViewCacheId
  );

  // const [filterTags, setFilterTags] = useState<Tag.Tag[]>([]);
  // const [filterCategories, setFilterCategories] = useState<Category.Category[]>([]);

  const isLocked = org?.lock.isLocked && user?.id !== org.lock.lockedBy;
  const lockTooltip = useMemo(() => {
    if (isLocked) {
      const user = (org.lock.lockedBy && org.users[org.lock.lockedBy]?.displayName) || '';
      return `Your ability to issue receipts is temporary paused while ${user}'s issuance is in progress.`;
    }
    return '';
  }, [isLocked, org?.lock, org?.users]);

  // ----- ACTIONS -------
  const handleView = (receiptId: string) => {
    navigate(PATHS.org.receipts.view, { receiptId });
  };

  // const handleFilterTagChange = (newValue: Tag.Tag[]) => setFilterTags(newValue);

  // const handleFilterCategoryChange = (newValue: Category.Category[]) =>
  //   setFilterCategories(newValue);

  const handleSearchChange = (filter: string) => {
    setPage(0);
    setSearch(filter);
  };

  const handleSetting = () => {
    navigate(getPath(PATHS.org.receipts.settings.receipts, { orgId: org!.id }));
  };

  // ----- FILTERING -------
  const data = useData({
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    search,

    // filters
    filterTags: [],
    filterCategories: [],
  });

  // ----- RENDERING -------
  const outletContext: TOutletContext = {
    type: 'receipt',
    columns,
    data,
    dateFrom,
    dateTo,
    orderBy: RECEIPT_LIST_HEADERS.find((h) => h.id === orderBy)?.label,
  };

  // This is to debug scenarios when a receipt is missing donor data
  // This should not happen, but if it does, we want to know about it
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const receiptsWithDonors = paginatedData.filter((receipt) => !!receipt._donor);
  const receiptsMissingDonors = paginatedData.filter((receipt) => !receipt._donor);

  if (receiptsWithDonors.length < paginatedData.length) {
    Sentry.captureMessage('Some receipts are missing donor data', {
      extra: {
        receiptsMissingDonors
      },
    });
  }

  return (
    <Page title="Receipts">
      <Outlet context={outletContext} />

      <Container maxWidth="xl">
        <BreadcrumbsHeader heading="Receipts">
          <Button onClick={handleSetting}>
            <Iconify color="#818991" icon="mdi:gear" style={{ fontSize: 26 }} />
          </Button>
        </BreadcrumbsHeader>

        <Stack spacing={2}>
          <Toolbar
            ctaReissueDisabled={isLocked || !data.length}
            ctaTooltip={lockTooltip}
            // filterTags={filterTags}
            // onFilterTagChange={handleFilterTagChange}
            // filterCategories={filterCategories}
            // onFilterCategoryChange={handleFilterCategoryChange}
            columns={columns}
            setColumns={setColumns}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            search={search}
            onSearchChange={handleSearchChange}
            actions={
              canEdit && (
                <>
                  <Tooltip title={lockTooltip}>
                    <span>
                      <Button
                        variant="contained"
                        disabled={!unreceiptedExist || isLocked}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        component={Link}
                        sx={{
                          textWrap : 'nowrap'
                        }}
                        to={getPath(PATHS.org.receipts.create.root, { orgId: org!.id })}
                      >
                        Issue Receipts
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )
            }
          />

          <Card>
            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="small">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    columns={columns}
                    rowCount={data.length}
                    onSort={onSort as (id: string) => void}
                  />

                  <TableBody>
                    {receiptsWithDonors
                      .map((receipt) => (
                        <Row
                          key={receipt.id}
                          columns={columns}
                          receipt={receipt}
                          onClick={handleView}
                        />
                      ))}

                    <TableEmptyRows
                      height={44}
                      emptyRows={emptyRows(page, rowsPerPage, data.length)}
                    />

                    {/* change this isNotFound */}
                    <TableNoData isNotFound={!data.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
